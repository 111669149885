import React from "react";
import bg from "../../assets/images/servbg.png";
import chennai from "../../assets/images/services/chennai.jpg";
import coimbatore from "../../assets/images/services/coimbatore.jpg";
import bangalore from "../../assets/images/services/bangalore.jpg";
import madurai from "../../assets/images/services/madurai.jpeg";
import pondicherry from "../../assets/images/services/pondi.jpg";
import trichy from "../../assets/images/services/trichy.jpeg";
import vellore from "../../assets/images/services/vellore.jpg";
import kumbakonam from "../../assets/images/services/kumbakonam.webp";
import salem from "../../assets/images/services/salem.jpg";
import OneWayServicesComponent from "./OneWayServicesComponent";
const OneWayServices = () => {
  return (
    <div className="serv_parent" style={{backgroundImage: `url(${bg})`}}>
      <div className="container py-5 md:py-8 lg:py-10 mx-auto px-4 sm:px-4 md:px-8 lg:px-16 text-white" id="cities">
        <h4 className="text-[#241D92] text-lg font-medium primary-color">
        One Way Taxi Service
        </h4>
        <h3 className="mt-4 font-bold text-3xl md:text-4xl">
        Popular Drop Taxi Routes
        </h3>
        <p className=" mt-4">Vellore Dropme Taxi 
 stands out from other service providers by offering competitive prices without compromising on quality or customer satisfaction. Our commitment to providing affordable transportation solutions allows travelers to experience these popular routes at a lower cost, making their journey enjoyable and pocket-friendly.</p>
        <div className=" mt-5 md:mt-7 lg:mt-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3">
           <OneWayServicesComponent place="CHENNAI" desc="Vellore Dropme Taxi ensures reliable transportation from 
           Vellore to Chennai, boasting a versatile fleet comprising SUVs, sedans, and Etios models. With well-maintained 
           cars and seasoned drivers, they prioritize passenger comfort and hassle-free travel. Suited for diverse needs,
            their services cater to both economical and luxurious preferences, ideal for business, leisure, or medical trips. Covering all Chennai areas including T. Nagar, Adyar, Velachery, and Anna Nagar, they serve tourists and locals alike. Offering one-way and round-trip options, their flexibility accommodates various travel plans. Exclusively operating through their website, 
            they provide seamless online booking, enabling customers to schedule pickups, select vehicles. Vellore Dropme Taxi's dedication to safety and customer satisfaction establishes them as a preferred choice for travelers,
            promising a reliable, comfortable, and enjoyable journey, whether it's a quick trip or a leisurely excursion." image={chennai} />
           {/* <OneWayServicesComponent place="COIMBATORE" desc="Covering a distance of approximately 346 kilometers, traveling from Chennai to Bangalore with Vellore Dropme Taxi 
 is a convenient and hassle-free option. In Bangalore, explore the magnificent Bangalore Palace, visit the famous Lalbagh Botanical Garden, and enjoy a shopping spree on the bustling MG Road. Vellore Dropme Taxi 
 provides this service at a low price compared to other service providers, making it a cost-effective choice for travelers." image={coimbatore} />
           <OneWayServicesComponent place="BANGALORE" desc="Covering a distance of approximately 346 kilometers, traveling from Chennai to Bangalore with Vellore Dropme Taxi 
 is a convenient and hassle-free option. In Bangalore, explore the magnificent Bangalore Palace, visit the famous Lalbagh Botanical Garden, and enjoy a shopping spree on the bustling MG Road. Vellore Dropme Taxi 
 provides this service at a low price compared to other service providers, making it a cost-effective choice for travelers." image={bangalore} />
           <OneWayServicesComponent place="SALEM" desc="Covering a distance of approximately 346 kilometers, traveling from Chennai to Bangalore with Vellore Dropme Taxi 
 is a convenient and hassle-free option. In Bangalore, explore the magnificent Bangalore Palace, visit the famous Lalbagh Botanical Garden, and enjoy a shopping spree on the bustling MG Road. Vellore Dropme Taxi 
 provides this service at a low price compared to other service providers, making it a cost-effective choice for travelers." image={salem} />
           <OneWayServicesComponent place="MADURAI" desc="Covering a distance of approximately 346 kilometers, traveling from Chennai to Bangalore with Vellore Dropme Taxi 
 is a convenient and hassle-free option. In Bangalore, explore the magnificent Bangalore Palace, visit the famous Lalbagh Botanical Garden, and enjoy a shopping spree on the bustling MG Road. Vellore Dropme Taxi 
 provides this service at a low price compared to other service providers, making it a cost-effective choice for travelers." image={madurai} />
           <OneWayServicesComponent place="PONDICHERRY" desc="Covering a distance of approximately 346 kilometers, traveling from Chennai to Bangalore with Vellore Dropme Taxi 
 is a convenient and hassle-free option. In Bangalore, explore the magnificent Bangalore Palace, visit the famous Lalbagh Botanical Garden, and enjoy a shopping spree on the bustling MG Road. Vellore Dropme Taxi 
 provides this service at a low price compared to other service providers, making it a cost-effective choice for travelers." image={pondicherry} /> */}
           <OneWayServicesComponent place="VELLORE" desc="Vellore Dropme Taxi provides reliable transportation from 
           Chennai to Vellore, offering a diverse range of vehicles including SUVs, sedans, and Etios models. 
           Ensuring a comfortable journey, their well-maintained vehicles and skilled drivers cater to various 
           preferences, from budget-conscious travelers to those seeking luxury. Ideal for business, leisure, or 
           medical visits, they guarantee a seamless experience. Covering Vellore's key landmarks such as VIT 
           University, Christian Medical College, and Vellore Fort, they serve both tourists and locals. Whether 
           for a one-way trip or a round-trip adventure, their service offers flexibility. Operating through their
            website, they offer a user-friendly platform for online booking, ensuring convenience and security. 
            Committed to safety and customer satisfaction, Vellore Dropme Taxi stands out as a trusted transportation 
            provider, delivering a comfortable and enjoyable travel experience with their SUVs, sedans, and Etios 
            models." image={vellore} />
           {/* <OneWayServicesComponent place="TRICHY" desc="Covering a distance of approximately 346 kilometers, traveling from Chennai to Bangalore with Vellore Dropme Taxi 
 is a convenient and hassle-free option. In Bangalore, explore the magnificent Bangalore Palace, visit the famous Lalbagh Botanical Garden, and enjoy a shopping spree on the bustling MG Road. Vellore Dropme Taxi 
 provides this service at a low price compared to other service providers, making it a cost-effective choice for travelers." image={trichy} />
           <OneWayServicesComponent place="KUMBAKONAM" desc="Covering a distance of approximately 346 kilometers, traveling from Chennai to Bangalore with Vellore Dropme Taxi 
 is a convenient and hassle-free option. In Bangalore, explore the magnificent Bangalore Palace, visit the famous Lalbagh Botanical Garden, and enjoy a shopping spree on the bustling MG Road. Vellore Dropme Taxi 
 provides this service at a low price compared to other service providers, making it a cost-effective choice for travelers." image={kumbakonam} /> */}
        </div>
      </div>

    </div>
  );
};

export default OneWayServices;
