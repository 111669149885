import React from 'react'
import BookingForm from "../components/BookingForm";
import Look from "../components/Look";
import OurServices from "../components/Home/OurServices";
import OneWayServices from "../components/Home/OneWayServices";
import TopRoutes from "../components/Home/TopRoutes";

const VelloreToChennai = () => {
  return (
    <div>
      <BookingForm />
      <TopRoutes />
      <OurServices />
      <OneWayServices />
 
      <Look />
  
      </div>
  )
}

export default VelloreToChennai