import React from "react";

import oneway from "../../assets/images/oneway.jpeg"
import roundtrip from "../../assets/images/roundtrip.jpeg"
import airporttrip from "../../assets/images/airporttrip.png"
import Packages from "../../assets/images/package.png";

const OurServices = () => {
  return (
    <div>
      <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
          Our Service
        </h4>
        <p className='text-black text-center opacity-60 md:text-lg mt-5'>
          
          Book your ride today and enjoy a seamless travel experience with our reliable drop taxi service from Vellore to Chennai & Chennai to Vellore               </p>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4  gap-x-3 sm:gap-y-2 md:gap-y-3  items-center px-4 py-5 bg-[#ffffff] justify-around rounded-md'>

            <div className='grid grid-cols-1 gap-x-3 sm:gap-y-2 md:gap-y-3  items-center px-4 py-5 bg-[#ffffff] justify-around rounded-md'>
              <div className="flex flex-col items-center gap-y-4">
                < div className="w-[100px] h-[100px]">
                   <img src ={oneway}/>
                   </div>
                <p className="font-bold text-3xl">One Way Trip</p>
                <p className="text-center">Convenient drop services from Vellore to Chennai.</p>
              </div>
            </div>
            <div className='grid grid-cols-1 gap-x-3 sm:gap-y-2 md:gap-y-3  items-center px-4 py-5 bg-[#ffffff] justify-around rounded-md'>
              <div className="flex flex-col items-center gap-y-3">
              <div className="w-[100px] h-[100px]">

                   <img src ={roundtrip}/></div>
                <p className="font-bold text-3xl">Round Trip</p>
                <p className="text-center">Option for round trips available upon request.</p>
              </div>
            </div>
            <div className='grid grid-cols-1  gap-x-3 sm:gap-y-2 md:gap-y-3  items-center px-4 py-5 bg-[#ffffff] justify-around rounded-md'>
              <div className="flex flex-col items-center gap-y-3">
              <div className="w-[100px] h-[100px]">

                   <img src ={airporttrip}/>
                   </div>
                <p className="font-bold text-3xl">Airport taxi</p>
                <p className="text-center">Reliable transportation to and from the airport.</p>
              </div>
            </div>
            <div className='grid grid-cols-1  gap-x-3 sm:gap-y-2 md:gap-y-3  items-center px-4 py-5 bg-[#ffffff] justify-around rounded-md'>
              <div className="flex flex-col items-center gap-y-3">
              <div className="w-[100px] h-[100px]">

                   <img src ={Packages} className="w-[100px] h-[100px]"/>
                   </div>
                <p className="font-bold text-2xl">Custom Packages</p>
                <p className="text-center">Reliable transportation to and from the airport.</p>
              </div>
            </div>

          </div>
        {/* <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Drop Taxi Services:</h3>
          <p>
            Whether you need a ride across town or to a neighboring city, our drop taxi services offer a convenient and cost-effective solution for your travel needs.

          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Airport Transfers:</h3>
          <p>
            Start or end your journey hassle-free with our reliable airport transfer services. We'll ensure you reach your destination on time, relaxed, and ready for your flight.

          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Outstation Trips:</h3>
          <p>
            Explore the beauty of Tamil Nadu and beyond with our customizable outstation trip packages. Sit back, relax, and let us take you on an unforgettable journey.
            Book Your Ride Today!
          </p>
        </div>
        <p className="mt-4">
          Experience the ultimate in convenience, reliability, and affordability with Vellore Dropme Taxi. Book your ride today and discover why we're the preferred choice for drop taxi services in Tamil Nadu. Get in touch with us now to plan your next journey!
        </p> */}
      </div>

    
    </div>
  );
};

export default OurServices;
