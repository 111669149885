import React from 'react'

const PricePlanComponents = ({place , image , oneway ,roundtrip}) => {
  return (
    <div className=" py-6 px-4 rounded-lg shadow-lg border">
    <div className=' flex flex-col justify-between flex-1 h-full'>
      <p className=" font-bold">{place}</p>
      <img src={image} className=" w-[200px] mx-auto" />
      <div className=" flex gap-x-2 justify-between mt-3 pb-3  border-b flex-1">
          <div className=" flex flex-col gap-y-2 flex-1 items-center">
              <span className="flex gap-x-1 font-bold align-baseline">
                  <span className=" primary-color text-xl">Rs</span>
                  <span className=" text-3xl ">{oneway}</span>

              </span>
              <span className=" opacity-50 font-semibold">One Way</span>
              <span className=" primary-color text-[11px] md:text-[16px] lg:text-[16px]">One Way-Minimum:130km*</span>

          </div>
          <div className=" flex flex-col gap-y-2 flex-1 items-center">
              <span className="flex gap-x-1 font-semibold align-baseline">
                  <span className=" primary-color text-xl">Rs</span>
                  <span className=" text-3xl">{roundtrip}</span>
              </span>
              <span className=" opacity-50 font-semibold">Round Trip</span>
              <span className=" primary-color text-[11px] md:text-[16px] lg:text-[16px]">Round Trip-Minimum:250km*</span>

          </div>
      </div>
      <div className='text-center'>Driver Beta, Toll,  Parking,  State  permit  extra*</div>

      <div className=" mt-3">
          <a  href="tel:+919597256055"  className="th-btn w-full">CHOOSE CAB</a>
      </div>
    </div>
  </div>
  )
}

export default PricePlanComponents
