import React from 'react'

const TopRoutesGrid = ({grid_image ,heading ,trip,rates,detail}) => {
    return (
        <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1  gap-x-3 sm:gap-y-2 md:gap-y-3  items-center px-4 py-5 bg-[#ffffff] justify-around rounded-md'>
            <div className='flex justify-center'><img src={grid_image} alt="place_image" className='w-[200px]  object-cover rounded-md'/></div>
            <div className=' flex flex-col justify-between'>
                <h4 className='text-[18px] text-center font-semibold'>{heading}<span className='text-[16px] font-none'>({trip})</span></h4>
                <p className='text-[#555] mt-2 text-center'>{detail}</p>
                <span className='text-[#e30d16] text-[18px] mt-2 inline-block font-medium text-center'>{rates}</span>
               
                <div className='text-center'>Toll,  Parking,  State  permit  extra*</div>
                <div className="flex flex-col  lg:flex-row gap-x-2 mt-2">

      <a  href="#book_taxi_form" className='th-btn mt-3'>BOOK NOW</a>
      <a  href="tel:+919597256055" className='th-btn-reverse mt-3'>CALL NOW</a>

      </div>
            </div>
        </div>
    )
}

export default TopRoutesGrid